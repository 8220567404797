import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const OrderFormHead = () => {
  const [t] = useTranslation();

  return (
    <>
      <Text
        mb="2"
        color="primary.default"
        textStyle={{ mobile: 'titleLarge', desktop: 'h1' }}
      >
        {t('orderForm.head')}
      </Text>
      <Text mb="6" textStyle={{ mobile: 'bodyMedium', desktop: 'bodyLarge' }}>
        {t('orderForm.subhead')}
      </Text>
    </>
  );
};
