import { Link, Text, TextProps } from '@chakra-ui/react';

/**
 * read https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
 */
export const GrecaptchaBadge = (props: TextProps) => {
  return (
    <Text
      my="2"
      align="center"
      textStyle="overline"
      color="text.grey"
      {...props}
    >
      This site is protected by reCAPTCHA and the Google{' '}
      <Link color="primary.default" href="https://policies.google.com/privacy">
        Privacy Policy
      </Link>{' '}
      and{' '}
      <Link color="primary.default" href="https://policies.google.com/terms">
        Terms of Service
      </Link>{' '}
      apply.
    </Text>
  );
};
