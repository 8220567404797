import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import {
  ActionType,
  ipc,
  SetFormHeightAction,
} from '@proptly/contractor-form-sdk';

let prevHeight = 0;
const handleResize = (formId: string | null) => {
  const scrollHeight = document.body.scrollHeight;
  if (prevHeight === scrollHeight) {
    return;
  }
  prevHeight = scrollHeight;
  const action: SetFormHeightAction = {
    source: 'PROPTLY_FORM',
    formId,
    type: ActionType.SetFormHeight,
    payload: scrollHeight + 1,
  };
  ipc.dispatch(action);
};

export const useResizeObserver = () => {
  const [searchParams] = useSearchParams();
  const formId = searchParams.get('formId');

  useEffect(() => {
    handleResize(formId);
    const observer = new ResizeObserver(() => handleResize(formId));
    observer.observe(document.body);

    return () => {
      observer.disconnect();
    };
  }, [formId]);
};
