import {
  CreateFormProjectPayload,
  ValueDto,
} from '@proptly/contractor-contract-classes';
import { APP_RECAPTCHA_HEADER } from '@proptly/shared';
import { ApiResponse, contractorClient, withRecaptcha } from '@proptly/ui';

export const createProject = async (data: CreateFormProjectPayload) =>
  withRecaptcha((recaptcha) =>
    contractorClient.post<ApiResponse<ValueDto<string>>>(
      '/project/form',
      data,
      {
        headers: {
          [APP_RECAPTCHA_HEADER]: recaptcha,
        },
      },
    ),
  ).then((resp) => resp.data.data);
