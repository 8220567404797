import {
  SendContactMessagePayload,
  ValueDto,
} from '@proptly/contractor-contract-classes';
import { APP_RECAPTCHA_HEADER } from '@proptly/shared';
import { ApiResponse, contractorClient, withRecaptcha } from '@proptly/ui';

export const sendContact = async (
  slug: string,
  data: SendContactMessagePayload,
) =>
  withRecaptcha((recaptcha) =>
    contractorClient.post<ApiResponse<ValueDto<string>>>(
      `/contractor/${slug}/contact`,
      data,
      {
        headers: {
          [APP_RECAPTCHA_HEADER]: recaptcha,
        },
      },
    ),
  ).then((resp) => resp.data.data);
