import { useEffect, useRef, useState } from 'react';

import { Input, Select, Textarea } from '@chakra-ui/react';
import { createContext } from '@chakra-ui/react-utils';
import { Global } from '@emotion/react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import {
  ActionType,
  FormSubmitSuccessAction,
  FormTarget,
  ipc,
} from '@proptly/contractor-form-sdk';
import { ns } from '@proptly/locale';
import { isEmptyOrWhitespace } from '@proptly/shared';
import { FormValidationError, SubmitButton } from '@proptly/ui';

import { createProject } from '../api/create-project';
import { sendContact } from '../api/send-contact';
import { ContactForm, ContactFormProps } from './contact-form';
import { ProjectForm, ProjectFormData } from './project-form';
import { SuccessMessage } from './success-message';

const handleSuccess = (formId: string | null) => {
  const action: FormSubmitSuccessAction = {
    source: 'PROPTLY_FORM',
    formId,
    type: ActionType.FormSubmitSuccess,
  };
  ipc.dispatch(action);
};

const changeVariant = (variant: 'outline' | 'filled') => {
  Input.defaultProps = {
    ...Input.defaultProps,
    variant,
  };
  Textarea.defaultProps = {
    ...Textarea.defaultProps,
    variant,
  };
  Select.defaultProps = {
    ...Select.defaultProps,
    variant,
  };
};

const changeBorderRadius = (borderRadius: string) => {
  Input.defaultProps = {
    ...Input.defaultProps,
    borderRadius,
  };
  Textarea.defaultProps = {
    ...Textarea.defaultProps,
    borderRadius,
  };
  Select.defaultProps = {
    ...Select.defaultProps,
    borderRadius,
  };
  SubmitButton.defaultProps = {
    borderRadius,
  };
};

export interface ContractorFormContextValue {
  target: FormTarget | null;
  showTargetPicker: boolean;
  setTarget: (target: FormTarget) => void;
  setDefaultValues: (values: object) => void;
}

const [ContractorFormContextProvider, useContext] = createContext<
  ContractorFormContextValue | undefined
>({
  name: 'FormGeneratorContext',
  strict: false,
});

export const useContractorFormContext = useContext;

export const ContractorForm = () => {
  const [et] = useTranslation(ns.Errors);
  const [searchParams] = useSearchParams();
  const slug = searchParams.get('slug');

  if (isEmptyOrWhitespace(slug) || slug === 'null' || slug === 'undefined') {
    throw new Error('Contractor slug was not provided');
  }

  const formId = searchParams.get('formId');
  const borderRadius = searchParams.get('borderRadius') ?? undefined;
  const primaryColor = searchParams.get('primaryColor');
  const variant = searchParams.get('variant');
  const targetParam = searchParams.get('target') as FormTarget | null;
  const defaultTargetParam = searchParams.get(
    'defaultTarget',
  ) as FormTarget | null;

  const [target, setTarget] = useState(targetParam || defaultTargetParam);

  useEffect(() => {
    const newVariant = variant === 'outline' ? variant : 'filled';
    changeVariant(newVariant);
  }, [variant]);

  useEffect(() => {
    if (!borderRadius) {
      return;
    }
    changeBorderRadius(borderRadius);
  }, [borderRadius]);
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = async (value: ProjectFormData) => {
    const _phoneNumber = parsePhoneNumberFromString(
      value.phoneNumber,
      value.phoneNumberPrefix,
    );

    const phoneNumber = _phoneNumber?.number;
    if (!phoneNumber) {
      throw new FormValidationError<ProjectFormData>(
        'phoneNumber',
        et('invalidPhoneNumber'),
      );
    }

    await createProject({
      slug,
      firstName: value.firstName,
      lastName: value.lastName,
      phoneNumber,
      email: value.email,
      address: value.address,
      city: value.city,
      postalCode: value.postalCode,
      description: value.message,
    });
    setIsSuccess(true);
    handleSuccess(formId);
  };

  const handleSubmitContact: ContactFormProps['onSubmit'] = async (value) => {
    await sendContact(slug, {
      email: value.email,
      body: value.message,
      name: `${value.firstName} ${value.lastName}`,
    });

    setIsSuccess(true);
    handleSuccess(formId);
  };

  const defaultValuesRef = useRef<object>({});
  const setDefaultValues = (v: object) =>
    Object.assign(defaultValuesRef.current, v);

  const formsMap = {
    contact: (
      <ContactForm
        isReadOnly={!target}
        onSubmit={handleSubmitContact}
        defaultValues={defaultValuesRef.current}
      />
    ),
    project: (
      <ProjectForm
        isReadOnly={!target}
        defaultValues={defaultValuesRef.current}
        onSubmit={onSubmit}
      />
    ),
  };

  const safeTargetRef = useRef(target);
  if (target) {
    safeTargetRef.current = target;
  }
  const safeTarget = safeTargetRef.current;

  const form = safeTarget ? formsMap[safeTarget] : formsMap.contact;

  return (
    <ContractorFormContextProvider
      value={{
        setTarget,
        showTargetPicker: !targetParam,
        target,
        setDefaultValues,
      }}
    >
      {primaryColor && (
        <Global
          styles={{ body: { '--chakra-colors-primary-default': primaryColor } }}
        />
      )}
      {isSuccess ? <SuccessMessage /> : form}
    </ContractorFormContextProvider>
  );
};
