import { useEffect, useState } from 'react';

import { Flex, Input, Select, Textarea } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import {
  ActionType,
  FormSubmitSuccessAction,
  ipc,
} from '@proptly/contractor-form-sdk';
import { ns } from '@proptly/locale';
import { isEmptyOrWhitespace } from '@proptly/shared';
import {
  ErrorBoundary,
  FormValidationError,
  SubmitButton,
  TryCompanyLogo,
  getTenantLogoUrl,
} from '@proptly/ui';

import { createOrder } from '../api/create-order';
import { OrderFormData, OrderForm } from './order-form';
import { TenantSuccessMessage } from './tenant-success-message';

const handleSuccess = (formId: string | null) => {
  const action: FormSubmitSuccessAction = {
    source: 'PROPTLY_FORM',
    formId,
    type: ActionType.FormSubmitSuccess,
  };
  ipc.dispatch(action);
};

const changeVariant = (variant: 'outline' | 'filled') => {
  Input.defaultProps = {
    ...Input.defaultProps,
    variant,
  };
  Textarea.defaultProps = {
    ...Textarea.defaultProps,
    variant,
  };
  Select.defaultProps = {
    ...Select.defaultProps,
    variant,
  };
};

const changeBorderRadius = (borderRadius: string) => {
  Input.defaultProps = {
    ...Input.defaultProps,
    borderRadius,
  };
  Textarea.defaultProps = {
    ...Textarea.defaultProps,
    borderRadius,
  };
  Select.defaultProps = {
    ...Select.defaultProps,
    borderRadius,
  };
  SubmitButton.defaultProps = {
    borderRadius,
  };
};

export const TenantForm = () => {
  const [t] = useTranslation();
  const [et] = useTranslation(ns.Errors);
  const [searchParams] = useSearchParams();
  const slug = searchParams.get('slug');

  if (isEmptyOrWhitespace(slug) || slug === 'null' || slug === 'undefined') {
    throw new Error('Tenant slug was not provided');
  }

  const formId = searchParams.get('formId');
  const borderRadius = searchParams.get('borderRadius') ?? undefined;
  const primaryColor = searchParams.get('primaryColor');
  const variant = searchParams.get('variant');

  useEffect(() => {
    const newVariant = variant === 'outline' ? variant : 'filled';
    changeVariant(newVariant);
  }, [variant]);

  useEffect(() => {
    if (!borderRadius) {
      return;
    }
    changeBorderRadius(borderRadius);
  }, [borderRadius]);
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = async (value: OrderFormData) => {
    const _phoneNumber = parsePhoneNumberFromString(
      value.phoneNumber,
      value.phoneNumberPrefix,
    );

    const phoneNumber = _phoneNumber?.number;
    if (!phoneNumber) {
      throw new FormValidationError<OrderFormData>(
        'phoneNumber',
        et('invalidPhoneNumber'),
      );
    }

    const contractorName =
      value.contractorName.trim() &&
      `${t('contractorName')}:\n${value.contractorName}`;

    const description = [contractorName, value.message]
      .map((s) => s.trim())
      .filter(Boolean)
      .join('\n\n');

    await createOrder({
      slug,
      firstName: value.firstName,
      lastName: value.lastName,
      phoneNumber,
      email: value.email,
      address: value.address,
      city: value.city,
      postalCode: value.postalCode,
      description,
    });
    setIsSuccess(true);
    handleSuccess(formId);
  };

  return (
    <>
      {primaryColor && (
        <Global
          styles={{ body: { '--chakra-colors-primary-default': primaryColor } }}
        />
      )}

      <ErrorBoundary fallbackRender={() => null}>
        <Flex justify="center" p="10">
          <TryCompanyLogo maxH="15" src={getTenantLogoUrl(slug)} />
        </Flex>
      </ErrorBoundary>
      {isSuccess ? <TenantSuccessMessage /> : <OrderForm onSubmit={onSubmit} />}
    </>
  );
};
