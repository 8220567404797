import { forwardRef, useFormControlContext } from '@chakra-ui/react';
import { isEmail } from 'class-validator';
import { useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';

import { InputField, InputFieldProps } from '../atoms/input-field';

export const EmailInputField = forwardRef<InputFieldProps, 'input'>(
  ({ ...props }, ref) => {
    const [t] = useTranslation(ns.Errors);
    const formControl = useFormControlContext();

    const isRequired = !!formControl.isRequired;

    return (
      <InputField
        {...props}
        validate={(value) => {
          const shouldValidate = isRequired || !!value;

          if (!shouldValidate) {
            return undefined;
          }

          return isEmail(value) ? undefined : t('invalidEmail');
        }}
        type="email"
        ref={ref}
      />
    );
  },
);
