import {
  Flex,
  forwardRef,
  useFormControlContext,
  VisuallyHidden,
} from '@chakra-ui/react';
import { MenuPlacement } from 'chakra-react-select';
import { isPhoneNumber } from 'class-validator';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';

import { InputField, InputFieldProps } from '../atoms';
import { PhonePrefixSelectField } from './phone-prefix-select';

export interface PhoneNumberInputFieldProps extends InputFieldProps {
  prefixName: string;
  prefixLabel: string;
  menuPlacement?: MenuPlacement;
}

export const PhoneNumberInputField = forwardRef<
  PhoneNumberInputFieldProps,
  'input'
>(({ prefixName, prefixLabel, menuPlacement, ...props }, ref) => {
  const [t] = useTranslation(ns.Errors);
  const formControl = useFormControlContext();
  const { getValues } = useFormContext();

  const { isRequired } = formControl;

  return (
    <Flex gap="2">
      <VisuallyHidden>
        <label htmlFor={prefixName}>{prefixLabel}</label>
      </VisuallyHidden>

      <PhonePrefixSelectField
        id={prefixName}
        name={prefixName}
        menuPlacement={menuPlacement}
      />

      <InputField
        {...props}
        validate={(value) => {
          const shouldValidate = isRequired || !!value;

          if (!shouldValidate) {
            return undefined;
          }

          return isPhoneNumber(value, getValues(prefixName))
            ? undefined
            : t('invalidPhoneNumber');
        }}
        type="tel"
        autoComplete="tel-national"
        ref={ref}
      />
    </Flex>
  );
});
