import { ChakraProvider } from '@chakra-ui/provider';
import { IconContext } from '@phosphor-icons/react';
import GTM from 'react-gtm-module';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { initI18n, useHtmlLangUpdater } from '@proptly/locale';
import propsOverride from '@proptly/props-override';
import { ErrorBoundary } from '@proptly/ui';

import { ContractorForm } from './components/contractor-form';
import { FormUsage } from './components/form-usage';
import { TenantForm } from './components/tenant-form';
import { useResizeObserver } from './hooks/use-resize-observer';
import { theme } from './theme/theme';

propsOverride();

const isDevelop = process.env.NODE_ENV === 'development';

const gtmId = process.env.NX_PUBLIC_CONTRACTOR_GTM_ID;

let isInitialised = false;
let initPromise: Promise<void>;

function useInitializer() {
  if (isInitialised) {
    return;
  }
  if (initPromise) {
    throw initPromise;
  }
  if (gtmId) {
    GTM.initialize({ gtmId });
  }

  initPromise = initI18n({
    cacheLang: false,
    order: ['querystring', 'navigator'],
  }).then(() => {
    isInitialised = true;
  });

  // throwing promise triggers suspense
  throw initPromise;
}

export function App() {
  useInitializer();
  useHtmlLangUpdater();
  useResizeObserver();

  return (
    <ErrorBoundary inContainer>
      <Routes>
        <Route path="form" element={<ContractorForm />} />
        <Route path="order" element={<TenantForm />} />
        {isDevelop && <Route path="*" element={<FormUsage />} />}
      </Routes>
    </ErrorBoundary>
  );
}

export default () => (
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <IconContext.Provider
        value={{
          size: 24,
        }}
      >
        <App />
      </IconContext.Provider>
    </BrowserRouter>
  </ChakraProvider>
);
