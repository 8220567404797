import { FormAction, FormActionType } from './actions';

const isInIframe = window.location !== window.parent.location;

const parentOrigin = isInIframe ? document.referrer : window.location.origin;
const parentWindow = isInIframe ? window.parent : window;

export const ipc = {
  dispatch: (action: FormAction) => {
    parentWindow.postMessage(action, parentOrigin || '*');
  },
  on: (handler: (e: MessageEvent<FormActionType>) => void) => {
    parentWindow.addEventListener('message', handler);
  },
  off: (handler: (e: MessageEvent<FormActionType>) => void) => {
    parentWindow.removeEventListener('message', handler);
  },
};
