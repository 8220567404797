import { Box } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StringLengthsEnum } from '@proptly/shared';
import {
  commonForm,
  CommonFormProps,
  EmailInputField,
  FormFieldControl,
  GrecaptchaBadge,
  handleFormValidationError,
  InputField,
  isFormValidationError,
  SubmitButton,
  TextareaField,
} from '@proptly/ui';

import { Branding } from './branding';
import { FormHead } from './form-head';
import { FormValuesSpy } from './form-values-spy';
import { RowOnDesktop } from './row-on-desktop';

export interface ContactFormData {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

const defaultValue: ContactFormData = {
  firstName: '',
  lastName: '',
  email: '',
  message: '',
};

export interface ContactFormProps extends CommonFormProps<ContactFormData> {}

export const ContactFormFields = ({ onSubmit }: ContactFormProps) => {
  const [t] = useTranslation();
  const form = useFormContext<ContactFormData>();

  const { handleSubmit, setError } = form;

  const beforeSubmit = async (values: ContactFormData) => {
    try {
      await onSubmit(values);
    } catch (error) {
      if (isFormValidationError<ContactFormData>(error)) {
        handleFormValidationError<ContactFormData>(setError, error);
      }
    }
  };

  return (
    <Box
      onSubmit={handleSubmit(beforeSubmit)}
      py="5"
      px={{ mobile: '5', desktop: '10' }}
      as="form"
      minW="300px"
    >
      <FormHead />

      <FormValuesSpy />

      <RowOnDesktop>
        <FormFieldControl isRequired name="firstName" label={t('firstName')}>
          <InputField
            name="firstName"
            maxLength={StringLengthsEnum.CharsLength150}
          />
        </FormFieldControl>

        <FormFieldControl isRequired name="lastName" label={t('lastName')}>
          <InputField
            name="lastName"
            maxLength={StringLengthsEnum.CharsLength150}
          />
        </FormFieldControl>
      </RowOnDesktop>

      <FormFieldControl isRequired name="email" label={t('email')}>
        <EmailInputField name="email" />
      </FormFieldControl>

      <FormFieldControl isRequired name="message" label={t('message')}>
        <TextareaField
          minRows={5}
          name="message"
          maxLength={StringLengthsEnum.CharsLength1000}
        />
      </FormFieldControl>
      <SubmitButton onClick={handleSubmit(beforeSubmit)} w="full">
        {t('sendForm')}
      </SubmitButton>
      <GrecaptchaBadge />
      <Branding />
    </Box>
  );
};

export const ContactForm = commonForm(ContactFormFields, { defaultValue });
