import { Box } from '@chakra-ui/react';
import { CountryCode } from 'libphonenumber-js';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StringLengthsEnum } from '@proptly/shared';
import {
  commonForm,
  CommonFormProps,
  EmailInputField,
  FormFieldControl,
  getDefaultCountry,
  GrecaptchaBadge,
  handleFormValidationError,
  InputField,
  isFormValidationError,
  PhoneNumberInputField,
  SubmitButton,
  TextareaField,
} from '@proptly/ui';

import { Branding } from './branding';
import { FormHead } from './form-head';
import { FormValuesSpy } from './form-values-spy';
import { RowOnDesktop } from './row-on-desktop';

export interface ProjectFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  phoneNumberPrefix: CountryCode;
  address: string;
  postalCode: string;
  city: string;
  message: string;
}

const defaultValue: ProjectFormData = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  phoneNumberPrefix: getDefaultCountry(),
  address: '',
  postalCode: '',
  city: '',
  message: '',
};

export interface ProjectFormProps extends CommonFormProps<ProjectFormData> {}

export const ProjectFormFields = ({ onSubmit }: ProjectFormProps) => {
  const [t] = useTranslation();
  const form = useFormContext<ProjectFormData>();

  const { handleSubmit, setError } = form;

  const beforeSubmit = async (values: ProjectFormData) => {
    try {
      await onSubmit(values);
    } catch (error) {
      if (isFormValidationError<ProjectFormData>(error)) {
        handleFormValidationError<ProjectFormData>(setError, error);
      }
    }
  };

  return (
    <Box
      onSubmit={handleSubmit(beforeSubmit)}
      py="5"
      px={{ mobile: '5', desktop: '10' }}
      as="form"
      minW="300px"
    >
      <FormHead />
      <FormValuesSpy />

      <RowOnDesktop>
        <FormFieldControl isRequired name="firstName" label={t('firstName')}>
          <InputField
            name="firstName"
            maxLength={StringLengthsEnum.CharsLength150}
          />
        </FormFieldControl>
        <FormFieldControl isRequired name="lastName" label={t('lastName')}>
          <InputField
            name="lastName"
            maxLength={StringLengthsEnum.CharsLength150}
          />
        </FormFieldControl>
      </RowOnDesktop>
      <FormFieldControl isRequired name="email" label={t('email')}>
        <EmailInputField name="email" />
      </FormFieldControl>
      <FormFieldControl isRequired name="phoneNumber" label={t('phoneNumber')}>
        <PhoneNumberInputField
          name="phoneNumber"
          prefixName="phoneNumberPrefix"
          prefixLabel={t('phoneNumberPrefix')}
        />
      </FormFieldControl>
      <FormFieldControl isRequired name="address" label={t('address')}>
        <InputField
          name="address"
          maxLength={StringLengthsEnum.CharsLength250}
        />
      </FormFieldControl>
      <RowOnDesktop>
        <FormFieldControl isRequired name="postalCode" label={t('postalCode')}>
          <InputField
            name="postalCode"
            maxLength={StringLengthsEnum.CharsLength50}
          />
        </FormFieldControl>
        <FormFieldControl isRequired name="city" label={t('city')}>
          <InputField
            name="city"
            maxLength={StringLengthsEnum.CharsLength150}
          />
        </FormFieldControl>
      </RowOnDesktop>
      <FormFieldControl name="message" label={t('additionalNotes')}>
        <TextareaField
          minRows={5}
          name="message"
          maxLength={StringLengthsEnum.CharsLength1000}
        />
      </FormFieldControl>
      <SubmitButton onClick={handleSubmit(beforeSubmit)} w="full">
        {t('sendForm')}
      </SubmitButton>
      <GrecaptchaBadge />
      <Branding />
    </Box>
  );
};

export const ProjectForm = commonForm(ProjectFormFields, { defaultValue });
