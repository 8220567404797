/* eslint-disable @typescript-eslint/no-explicit-any */

import { mergeRefs } from '@chakra-ui/react-utils';
import { Props, Select } from 'chakra-react-select';
import { Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';

import { useCommonControllerProps } from '../../../hooks';
import { AutocompleteCommonProps } from './common';
import { useCommonProps } from './use-common-props';

export interface AutocompleteV2FieldProps<T = any>
  extends AutocompleteCommonProps {
  options: NonNullable<Props<AutocompleteV2OptionType<T>>['options']>;
  optionAsValue?: boolean;
}

export type AutocompleteV2OptionType<T = any> = T & {
  value: string;
  label: string;
};

export const AutocompleteV2Field = ({
  optionAsValue,
  ...props
}: AutocompleteV2FieldProps) => {
  const [ct] = useTranslation(ns.Components);
  const { getContinueSearchProps, onOptionSelect, ...commonProps } =
    useCommonProps(props);
  const controllerProps = useCommonControllerProps(props.name);
  const getOptionFromValue = (value: unknown) =>
    props.options
      .flatMap((group) => ('options' in group ? group.options : group))
      .find((option) => option.value === value) ?? null;

  const getValueFromOption = (option: AutocompleteV2OptionType) => option.value;

  return (
    <Controller
      {...controllerProps}
      render={({ field }) => {
        const value = optionAsValue
          ? field.value
          : getOptionFromValue(field.value);

        return (
          <Select<AutocompleteV2OptionType>
            noOptionsMessage={({ inputValue }) =>
              inputValue ? (
                <Trans
                  t={ct}
                  i18nKey="autocomplete.noOptionsSearched"
                  tOptions={{ phrase: inputValue }}
                />
              ) : (
                <Trans t={ct} i18nKey="autocomplete.noOptions" />
              )
            }
            {...field}
            {...commonProps}
            {...getContinueSearchProps(value)}
            ref={mergeRefs(field.ref, commonProps.ref)}
            value={value}
            onChange={(option) => {
              if (!optionAsValue) {
                field.onChange(option && getValueFromOption(option));
              } else {
                field.onChange(option);
              }
              onOptionSelect(option);
            }}
          />
        );
      }}
    />
  );
};
