/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';

import {
  GroupBase,
  OptionsOrGroups as NativeOptionsOrGroups,
  Props,
  SelectInstance,
} from 'chakra-react-select';

export interface AutocompleteCommonProps<Option = any>
  extends Pick<
    Props<Option>,
    | 'noOptionsMessage'
    | 'menuPlacement'
    | 'menuPosition'
    | 'id'
    | 'getOptionLabel'
    | 'getOptionValue'
    | 'onInputChange'
    | 'inputValue'
    | 'placeholder'
  > {
  /** name of key in form state */
  name: string;
  /** how option should look like in menu list */
  renderOption?: (option: Option) => ReactNode;
  /** how option should look like in input container when option is select */
  renderCurrentValue?: (option: Option) => ReactNode;
  /** when dropdown indicator should be shown */
  dropdownIndicator?: boolean | 'on-focus' | '!on-focus';
  /** when clear button should be shown */
  clearButton?: boolean | 'on-focus' | '!on-focus';
  /** allows clear button to clear selected value so state would be set to null,
   *  by default once state is set there is no option to clear that */
  clearSelectedValue?: boolean;
  /** search icon should be shown */
  searchIndicator?: boolean;
  /** hides group header visible above each group in menu list */
  hideGroupHeader?: boolean;
  /** whether after selecting value from list user should be able to continue typing in input,
   * provide a function that maps option to string that will be used to continue typing from  */
  continueSearching?: (option: Option) => string;
  /** handler called after selecting an option */
  onOptionSelect?: (option: Option | null) => void;
  /** input autocomplete */
  autoComplete?: string;
  /** internal reference to select instance - do not use */
  _ref?: SelectInstance;
}

export type OptionsOrGroups<T> = NativeOptionsOrGroups<T, GroupBase<T>>;

export const shouldShow = (
  setting: boolean | 'on-focus' | '!on-focus',
  { isFocused }: { isFocused: boolean },
) => {
  if (typeof setting === 'boolean') {
    return setting;
  }
  if (setting === 'on-focus') {
    return isFocused;
  }
  if (setting === '!on-focus') {
    return !isFocused;
  }

  return false;
};

export const chakraStyles: Props<any>['chakraStyles'] = {
  input: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '1rem',
    _selected: {
      bg: 'primary.shade',
      color: 'text.dark',
      _active: {
        bg: 'primary.shade',
      },
    },
  }),
  menuList: (provided) => ({
    ...provided,
    shadow: 'popover',
    border: 'unset',
    minW: 'fit-content',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    mx: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    pr: 1,
  }),
};
