import { useMemo } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import {
  CountryCode,
  getCountries,
  getCountryCallingCode,
  PhoneNumber,
} from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';

import { useCurrentLang } from '@proptly/locale';

import { countryCodeDisplayName } from '../../utils';
import {
  AutocompleteV2Field,
  AutocompleteV2FieldProps,
  AutocompleteV2OptionType,
  FlagIcon,
} from '../atoms';

type PhoneNumberPrefixOption = AutocompleteV2OptionType<{
  countryCallingCode: string;
}>;

const getPhoneNumberPrefixOption = (
  country: CountryCode,
  locales?: Intl.LocalesArgument,
): PhoneNumberPrefixOption => {
  const countryCallingCode = getCountryCallingCode(country);
  const countryName = countryCodeDisplayName(country, locales);

  return {
    value: country,
    label: `${countryName} +${countryCallingCode} `,
    countryCallingCode,
  };
};

const countriesToIgnore = [
  // Uses UK calling code
  'GG', // Guernsey +44
  'IM', // Isle of Man +44
  'JE', // Jersey +44

  // Uses Norway calling code
  'SJ', // Svalbard & Jan Mayen +47

  // Uses Finland calling code
  'AX', // Åland Islands +358

  // Uses Australia calling code
  'CC', // Cocos (Keeling) Islands +61
  'CX', // Christmas Island +61

  // Uses Reunion/France calling code
  'YT', // Mayotte +262

  // Uses St Helena calling code
  'TA', // Tristan da Cunha +290

  // Uses Morocco calling code
  'EH', // Western Sahara +212
];

export const getCountryCodeFromPhoneNumber = (phoneNumber: PhoneNumber) =>
  phoneNumber.country ||
  phoneNumber
    .getPossibleCountries()
    .filter((c) => !countriesToIgnore.includes(c))[0];

const countriesToUse = getCountries().filter(
  (c) => !countriesToIgnore.includes(c),
);

const getCountryPrefixes = (locales?: Intl.LocalesArgument) =>
  countriesToUse
    .map((countryCode) => getPhoneNumberPrefixOption(countryCode, locales))
    .sort((a, b) => a.label.localeCompare(b.label));

export type PhonePrefixSelectFieldProps = Omit<
  AutocompleteV2FieldProps,
  'options'
>;

const renderOption = (option: PhoneNumberPrefixOption) => (
  <Flex align="center" gap="1.5" ml="0.5">
    <FlagIcon countryCode={option.value} />
    <Text textStyle="labelMedium">+{option.countryCallingCode}</Text>
  </Flex>
);

export const PhonePrefixSelectField = (props: PhonePrefixSelectFieldProps) => {
  const [t] = useTranslation();
  const currentLang = useCurrentLang();
  const countryPrefixes = useMemo(
    () => getCountryPrefixes(currentLang),
    [currentLang],
  );

  return (
    <Box minW="120px" w="120px">
      <AutocompleteV2Field
        {...props}
        options={countryPrefixes}
        renderOption={renderOption}
        renderCurrentValue={renderOption}
        noOptionsMessage={() => t('noResults')}
        autoComplete="tel-country-code"
      />
    </Box>
  );
};
