import { CreateFormOrderPayload } from '@proptly/routing-contract-classes';
import { APP_RECAPTCHA_HEADER } from '@proptly/shared';
import { ApiResponse, routingClient, withRecaptcha } from '@proptly/ui';

export const createOrder = async (data: CreateFormOrderPayload) =>
  withRecaptcha((recaptcha) =>
    routingClient.post<ApiResponse<unknown>>('/order/form', data, {
      headers: {
        [APP_RECAPTCHA_HEADER]: recaptcha,
      },
    }),
  ).then((resp) => resp.data.data);
