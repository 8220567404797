import { Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { proptlyLogo } from '@proptly/ui';

export const Branding = () => {
  const [t] = useTranslation();

  return (
    <Flex
      mt="6"
      py="1"
      color="text.grey"
      gap="2"
      align="center"
      justify="center"
    >
      <Text textStyle="labelMedium">{t('poweredBy')}</Text>
      <Image src={proptlyLogo} h="24px" />
    </Flex>
  );
};
