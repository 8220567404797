import { StrictMode, Suspense } from 'react';

import * as ReactDOM from 'react-dom/client';

import { FixedSpinner } from '@proptly/ui';

import App from './app';

function bootstrap() {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );
  root.render(
    <StrictMode>
      <Suspense fallback={<FixedSpinner />}>
        <App />
      </Suspense>
    </StrictMode>,
  );
}

bootstrap();
