import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import { Box, Flex, IconButton } from '@chakra-ui/react';
import { CaretDown, MagnifyingGlass, X } from '@phosphor-icons/react';
import { chakraComponents, components } from 'chakra-react-select';

import { AutocompleteCommonProps, shouldShow } from './common';

const {
  Input,
  DropdownIndicator,
  ValueContainer,
  GroupHeading,
  Option,
  Group,
  SingleValue,
} = chakraComponents;
const { MenuPortal } = components;

const InputOverride: typeof Input = (props) => {
  const id = props.selectProps.instanceId?.toString();
  const { autoComplete } = props.selectProps as AutocompleteCommonProps;

  return (
    <Input
      {...props}
      autoComplete={autoComplete}
      {...(id && {
        'data-testid': id,
        id,
      })}
    />
  );
};

const DropdownIndicatorOverride: typeof DropdownIndicator = (props) => {
  const { isFocused, clearValue } = props;

  const {
    inputValue,
    onInputChange,
    clearButton = 'on-focus',
    dropdownIndicator = '!on-focus',
    clearSelectedValue,
    _ref,
  } = props.selectProps as AutocompleteCommonProps;

  const hasNoValue = clearSelectedValue
    ? !props.hasValue && !inputValue
    : !inputValue;

  const _clearValue = () => {
    if (clearSelectedValue) {
      clearValue();
    } else {
      onInputChange?.('', {
        action: 'set-value',
        prevInputValue: inputValue as string,
      });
    }
    setImmediate(() => {
      _ref?.focusInput();
    });
  };

  const renderClear = () => {
    if (hasNoValue || !shouldShow(clearButton, { isFocused })) {
      return null;
    }

    return (
      <IconButton
        size="sm"
        onTouchStart={(e) => {
          e.stopPropagation();
          _clearValue();
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
          _clearValue();
        }}
        tabIndex={-1}
        variant="ghost"
        icon={<X size={24} />}
        aria-label="Clear"
      />
    );
  };

  const renderDropdownIndicator = () => {
    if (!shouldShow(dropdownIndicator, { isFocused })) {
      return null;
    }

    return <CaretDown size={24} />;
  };

  return (
    <Flex align="center" mr="2">
      {renderClear()}
      {renderDropdownIndicator()}
    </Flex>
  );
};

const ValueContainerOverride: typeof ValueContainer = (props) => {
  const { searchIndicator: showIcon } =
    props.selectProps as AutocompleteCommonProps;

  return (
    <>
      {showIcon && (
        <Box pl="2">
          <MagnifyingGlass />
        </Box>
      )}

      <ValueContainer {...props} />
    </>
  );
};

const INPUT_HEIGHT = 48;

const MenuPortalOverride: typeof MenuPortal = (props) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  containerRef.current = container;

  const [menu, setMenu] = useState<HTMLElement | null>(null);
  const menuRef = useRef<HTMLElement | null>(null);
  menuRef.current = menu;

  const fixPosition = useCallback(() => {
    const container = containerRef.current;
    const menu = menuRef.current;

    if (!container || !menu || !anchor) {
      return;
    }

    const menuRect = menu.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    const anchorRect = anchor.getBoundingClientRect();

    let offset = anchorRect.top - containerRect.top;

    // Detect if menu wants to be on above or below the input
    // and move the container to right place
    if (menuRect.top < containerRect.top) {
      offset -= INPUT_HEIGHT;
    }
    if (!offset) {
      return;
    }
    const style = window.getComputedStyle(container);
    const transformMatrix = new WebKitCSSMatrix(style.transform);
    // it stores current transform on y axis
    const currentOffset = transformMatrix.m42;
    container.style.transform = `translateY(${currentOffset + offset}px)`;
  }, [anchor]);

  useLayoutEffect(() => {
    const frame = () => {
      fixPosition();
      animationFrame = requestAnimationFrame(frame);
    };
    let animationFrame = requestAnimationFrame(frame);

    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, [fixPosition]);

  useLayoutEffect(() => {
    if (!container) {
      return;
    }
    const _setMenu = () => {
      setMenu(
        (container.firstElementChild?.firstElementChild as HTMLElement) ?? null,
      );
    };

    const observer = new MutationObserver(_setMenu);
    observer.observe(container, { childList: true });

    _setMenu();

    return () => {
      observer.disconnect();
    };
  }, [container]);

  // Using transform in container positions fixed elements relative to this container
  // anchor div is mounted below input and it is used to calculate correct menu coordinates
  return (
    <>
      <Box ref={setAnchor} />
      <Box
        position="fixed"
        transform="translateY(0px)"
        zIndex="9999"
        ref={setContainer}
      >
        <Box as={MenuPortal} {...props} inset="unset !important" />
      </Box>
    </>
  );
};

const GroupHeadingOverride: typeof GroupHeading = (props) => {
  const { hideGroupHeader } = props.selectProps as AutocompleteCommonProps;

  if (hideGroupHeader) {
    return null as never;
  }

  return <GroupHeading {...props} />;
};

const OptionOverride: typeof Option = (props) => {
  const { renderOption } = props.selectProps as AutocompleteCommonProps;

  if (!renderOption) {
    return <Option {...props} />;
  }

  return <Option {...props}>{renderOption(props.data)}</Option>;
};

const GroupOverride: typeof Group = (props) => {
  return (
    <Box
      as={Group}
      {...props}
      _notFirst={{
        borderTop: '1px solid var(--chakra-colors-grey-shade)',
      }}
    />
  );
};

const SingleValueOverride: typeof SingleValue = (props) => {
  const { renderCurrentValue } = props.selectProps as AutocompleteCommonProps;

  if (!renderCurrentValue) {
    return <SingleValue {...props} />;
  }

  return <SingleValue {...props}>{renderCurrentValue(props.data)}</SingleValue>;
};

export const useComponentsOverride = (): Partial<typeof components> => {
  return {
    Input: InputOverride,
    ValueContainer: ValueContainerOverride,
    DropdownIndicator: DropdownIndicatorOverride,
    MenuPortal: MenuPortalOverride,
    GroupHeading: GroupHeadingOverride,
    Option: OptionOverride,
    Group: GroupOverride,
    SingleValue: SingleValueOverride,
    LoadingIndicator: undefined,
  };
};
