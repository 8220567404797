import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const SuccessMessage = () => {
  const [t] = useTranslation();

  return (
    <Box p="10" textAlign="center">
      <Text mb="2" color="primary.default" textStyle="h1">
        {t('contactForm.successHead')}
      </Text>
      <Text mb="6" textStyle="bodyLarge">
        {t('contactForm.successSubhead')}
      </Text>
    </Box>
  );
};
