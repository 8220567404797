import { Icon } from '@chakra-ui/react';
import * as Flags from 'country-flag-icons/react/3x2';

type CountryCodeWithFlag = keyof typeof Flags;

export interface FlagIconProps {
  countryCode: string;
}

export const FlagIcon = ({ countryCode }: FlagIconProps) => {
  const Component = Flags[countryCode as CountryCodeWithFlag];
  if (!Component) {
    return null;
  }

  return (
    <Icon
      verticalAlign="middle"
      filter="drop-shadow(0 0 1px #666)"
      as={Component}
      w="16px"
    />
  );
};
