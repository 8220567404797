import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormTarget } from '@proptly/contractor-form-sdk';

import { useContractorFormContext } from './contractor-form';

export const TargetPicker = () => {
  const [t] = useTranslation();
  const context = useContractorFormContext();
  const { isSubmitting } = useFormState();
  if (!context?.showTargetPicker) {
    return null;
  }

  const { target, setTarget } = context;

  return (
    <FormControl isReadOnly={isSubmitting} isRequired mb="4">
      <FormLabel>{t('contactForm.selectTarget')}</FormLabel>
      <Select
        disabled={isSubmitting}
        placeholder={t('selectOption')}
        value={target ?? undefined}
        onChange={(e) => {
          setTarget(e.target.value as FormTarget);
        }}
      >
        <option value="contact">{t('contactForm.generalInquiry')}</option>
        <option value="project">{t('contactForm.projectInquiry')}</option>
      </Select>
    </FormControl>
  );
};
