import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { TargetPicker } from './target-picker';

export const FormHead = () => {
  const [t] = useTranslation();

  return (
    <>
      <Text
        mb="2"
        color="primary.default"
        textStyle={{ mobile: 'titleLarge', desktop: 'h1' }}
      >
        {t('contactForm.head')}
      </Text>
      <Text mb="6" textStyle={{ mobile: 'bodyMedium', desktop: 'bodyLarge' }}>
        {t('contactForm.subhead')}
      </Text>

      <TargetPicker />
    </>
  );
};
