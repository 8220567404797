import { chakra, Flex } from '@chakra-ui/react';

export const RowOnDesktop = chakra(Flex, {
  baseStyle: {
    columnGap: '4',
    flexDir: {
      mobile: 'column',
      desktop: 'row',
    },
  },
});
