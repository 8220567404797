import {
  CountryCode,
  countryBoundingBoxes,
  countryCodes,
  countryCoordinates,
} from '@proptly/shared';

export const countryCodeDisplayName = (
  countryCode: string,
  locales?: Intl.LocalesArgument,
) => {
  try {
    return (
      new Intl.DisplayNames(locales, { type: 'region' }).of(countryCode) ??
      countryCode
    );
  } catch {
    return countryCode;
  }
};

export const getCountryCodes = () => {
  return countryCodes as unknown as CountryCode[];
};

export const getCoordinateForCountryCode = (countryCode: string) => {
  return countryCoordinates[countryCode];
};

export const getBoundingBoxForCountryCode = (countryCode: string) => {
  return countryBoundingBoxes[countryCode];
};

export const getDefaultCountryCode = (): CountryCode => 'NO';
