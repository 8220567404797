import { PropsWithChildren, useCallback, useState } from 'react';

import {
  Box,
  Flex,
  forwardRef,
  Input,
  InputProps,
  NumberInput,
  NumberInputField as ChakraNumberInputField,
  NumberInputProps,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { CommonInputProps, useCommonInputProps } from '../../hooks';

export interface InputFieldProps extends InputProps, CommonInputProps {
  name: string;
}

export const InputField = forwardRef<InputFieldProps, 'input'>((props, ref) => {
  const commonProps = useCommonInputProps(props, ref);

  return <Input {...commonProps} />;
});

InputField.id = Input.id;

export interface NumberInputFieldProps
  extends Omit<InputFieldProps, 'min' | 'max' | 'onChange'> {
  precision?: number;
  onChange?: NumberInputProps['onChange'];
}

export const NumberInputField = forwardRef<NumberInputFieldProps, 'input'>(
  ({ precision, onChange, ...props }, ref) => {
    const { onChange: commonOnChange, ...commonProps } = useCommonInputProps(
      { ...props, valueAsNumber: true },
      ref,
    );
    const { setValue: setFormValue, formState } = useFormContext();

    const { isSubmitted } = formState;
    const name = props.name;

    const setValue = useCallback(
      (value: number) => {
        setFormValue(name, value, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: isSubmitted,
        });
      },
      [name, setFormValue, isSubmitted],
    );

    return (
      <NumberInput
        min={props.moreThanOrEqual ?? props.moreThan}
        max={props.lessThanOrEqual ?? props.lessThan}
        precision={precision}
        onChange={(valueAsString, valueAsNumber) => {
          onChange?.(valueAsString, valueAsNumber);
          setValue(valueAsNumber);
        }}
      >
        <ChakraNumberInputField {...commonProps} />
      </NumberInput>
    );
  },
);

export interface NumberInputFieldWithPostfixProps
  extends NumberInputFieldProps {
  /** if postfix is longer than one character tweak input right padding until it fits - edge case for long input  */
  postfix: string;
}

export const NumberInputFieldWithPostfix = forwardRef<
  NumberInputFieldWithPostfixProps,
  'input'
>(({ postfix, ...props }, ref) => {
  const { getValues } = useFormContext();
  const [value, setValue] = useState(() => getValues([props.name]) + '' || '');

  return (
    <Box position="relative">
      <NumberInputField {...props} onChange={setValue} ref={ref} />
      <NumberInputFieldPostfix value={value}>{postfix}</NumberInputFieldPostfix>
    </Box>
  );
});

interface NumberInputFieldPostfixProps extends PropsWithChildren {
  value: string;
}

const NumberInputFieldPostfix = ({
  children,
  value,
}: NumberInputFieldPostfixProps) => {
  if (!value) {
    return null;
  }

  return (
    <Flex
      top="0"
      left="2px"
      h="full"
      px="4"
      position="absolute"
      pointerEvents="none"
      color="primary.default"
      align="center"
      maxW="100%"
    >
      <Box flex="1" overflow="hidden">
        <span style={{ opacity: 0 }}>{value}</span>
      </Box>
      <Box>{children}</Box>
    </Flex>
  );
};
