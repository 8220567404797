import { useEffect } from 'react';

import { useCallbackRef } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';

import { useContractorFormContext } from './contractor-form';

export const FormValuesSpy = () => {
  const values = useWatch();
  const { setDefaultValues } = useContractorFormContext() || {};

  const setDefaultValuesRef = useCallbackRef(setDefaultValues);

  useEffect(() => {
    setDefaultValuesRef(values);
  }, [setDefaultValuesRef, values]);

  return null;
};
