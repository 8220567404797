/* eslint-disable @typescript-eslint/no-explicit-any */
export enum ActionType {
  SetFormHeight = 'SET_FORM_HEIGHT',
  FormSubmitSuccess = 'FORM_SUBMIT_SUCCESS',
}

export interface BaseAction {
  source: 'PROPTLY_FORM';
  formId: string | null;
  type: ActionType;
  payload?: unknown;
}

export interface SetFormHeightAction extends BaseAction {
  type: ActionType.SetFormHeight;
  payload: number;
}

export interface FormSubmitSuccessAction extends BaseAction {
  type: ActionType.FormSubmitSuccess;
  payload?: never;
}

export type FormAction = SetFormHeightAction | FormSubmitSuccessAction;

export type FormActionType<T extends ActionType = ActionType> = Extract<
  FormAction,
  { type: T }
>;

export type FormActionPayload<T extends ActionType = ActionType> =
  FormActionType<T>['payload'];

export type FormActionHandler<T extends ActionType = ActionType> = (
  payload: FormActionType<T>['payload'],
) => void;

export const isFormAction = (data: any): data is FormAction => {
  return data?.source === 'PROPTLY_FORM';
};
