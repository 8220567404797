import { useRef, useState } from 'react';

import { useDrawerContext, useModalContext } from '@chakra-ui/react';
import { MenuPosition, SelectInstance } from 'chakra-react-select';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';

import { useOptionalContext } from '../../../hooks/use-optional-context';
import { useCommonFormContext } from '../../functional';
import { AutocompleteCommonProps, chakraStyles } from './common';
import { useComponentsOverride } from './use-components-override';

export const useCommonProps = ({
  id: idProp,
  continueSearching,
  onOptionSelect,
  ...props
}: AutocompleteCommonProps) => {
  const [ct] = useTranslation(ns.Components);
  const components = useComponentsOverride();
  const isInModal = !!useOptionalContext(useModalContext);
  const isInDrawer = !!useOptionalContext(useDrawerContext);
  const selectRef = useRef<SelectInstance | null>(null);

  const { isSubmitting } = useFormState();
  const { isReadOnly } = useCommonFormContext() || {};
  const isDisabled = isReadOnly || isSubmitting;

  const [inputValue, setInputValue] = useState('');

  const menuPosition: MenuPosition =
    isInModal && !isInDrawer ? 'fixed' : 'absolute';
  const id = idProp || props.name;

  const setInputValueToContinueSearch = (option: unknown) => {
    const selectInstance = selectRef.current;
    if (!continueSearching || !selectInstance || !option) {
      return;
    }
    setInputValue(continueSearching(option));
    // changing value in this manner will trigger searching
    selectInstance.onInputChange(continueSearching(option), {
      action: 'set-value',
      prevInputValue: inputValue,
    });
  };

  return {
    ...(isDisabled && { isDisabled }),
    components,
    chakraStyles,
    menuPosition,
    inputValue,
    minMenuHeight: 248,
    maxMenuHeight: 248,
    colorScheme: 'primary',
    useBasicStyles: true,
    openMenuOnFocus: true,
    placeholder: ct('autocomplete.placeholder'),
    loadingMessage: () => ct('autocomplete.loading'),
    ...props,
    instanceId: id,
    id: `react-select-${id}`,
    onInputChange: (newValue: string) => setInputValue(newValue),
    ref: selectRef,
    _ref: selectRef.current,
    onOptionSelect: (option: unknown | null) => {
      onOptionSelect?.(option);
    },
    getContinueSearchProps: (option: unknown) => {
      if (!continueSearching) {
        return;
      }

      return {
        onFocus: () => {
          setInputValueToContinueSearch(option);
        },
        onMenuOpen: () => {
          setInputValueToContinueSearch(option);
        },
      };
    },
  };
};
