import { extendTheme } from '@chakra-ui/react';

import { theme as baseTheme } from '@proptly/ui';

export const theme = extendTheme(
  {
    styles: {
      global: {
        body: {
          bgColor: 'transparent',
        },
      },
    },
  },
  baseTheme,
);
